
import OrganizationsActions from "@/components/organizations/Actions"
import OrganizationsList from "@/components/organizations/List"
import { mapState } from 'vuex'

export default {
  name: "Organizations",
  components: {
    OrganizationsActions,
    OrganizationsList,
  },
  data() {
    return {
      mode: 'list',
      modes: [
        { title: 'List', code: 'list' },
        { title: 'Add', code: 'add' },
      ],
      organizationForAction: null,
      action: null,
    }
  },
  computed: {
    ...mapState('organizations', [
      'getOrganizationsResult',
      'getOrganizationsIsLoading',
      'getOrganizationsError',

      'removeOrganizationsItemResult',
      'removeOrganizationsItemIsLoading',
      'removeOrganizationsItemError',

      'createOrganizationsItemResult',
      'createOrganizationsItemIsLoading',
      'createOrganizationsItemError',

      'editOrganizationsItemResult',
      'editOrganizationsItemIsLoading',
      'editOrganizationsItemError',
    ]),
    ...mapState('users', [
      'getCurrentProfileResult',
      'getCurrentProfileIsLoading',
      'getCurrentProfileError',
    ]),
    isLoading() {
      return this.getCurrentProfileIsLoading ||
        this.getOrganizationsIsLoading ||
        this.createOrganizationsItemIsLoading ||
        this.editOrganizationsItemIsLoading ||
        this.removeOrganizationsItemIsLoading;
    }
  },
  methods: {
    setMode(newCode) {
      if (newCode === 'add') {
        this.organizationForAction = null;
        this.action = null;
      }
      this.mode = newCode;
    },
    deleteHandler(organization) {
      this.organizationForAction = organization;
      this.action = 'delete';
    },
    editHandler(organization) {
      this.organizationForAction = organization;
      this.action = 'edit';
    },
    cancelHandler() {
      this.organizationForAction = null;
      this.action = null;
      this.mode = 'list';
    },
  },
  beforeMount() {
    if (!this.getOrganizationsResult && !this.getOrganizationsIsLoading) {
      this.$store.dispatch('organizations/getOrganizations');
    }
  },
  watch: {
    removeOrganizationsItemIsLoading(newVal, prevVal) {
      if (!newVal && prevVal) {
        this.$store.dispatch('organizations/getOrganizations');
      }
    }
  }
}

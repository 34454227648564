
import Loader from '../Loader'
import { mapState } from 'vuex';

export default {
  name: 'OrganizationsActions',
  components: {
    Loader,
  },
  props: {
    organization: {
      type: Object,
      default: null,
    },
    action: {
      type: String || null,
      default: null,
    },
    widgetClasses: String,
  },
  data() {
    return {
      name: '',
    }
  },
  computed: {
    ...mapState('organizations', [
      'createOrganizationsItemResult',
      'createOrganizationsItemIsLoading',
      'createOrganizationsItemError',

      'editOrganizationsItemResult',
      'editOrganizationsItemIsLoading',
      'editOrganizationsItemError',

      'removeOrganizationsItemResult',
      'removeOrganizationsItemIsLoading',
      'removeOrganizationsItemError',
    ]),
    title() {
      if (this.action === 'delete') {
        return 'Remove Organization';
      }
      if (this.action === 'edit') {
        return 'Edit Organization';
      }
      return 'Add Organization';
    },
    vendor() {
      return this.$store.state.users.getCurrentProfileResult.vendor;
    },
    isLoading() {
      return this.createOrganizationsItemIsLoading ||
        this.removeOrganizationsItemIsLoading ||
        this.editOrganizationsItemIsLoading;
    },
    isDisabled() {
      return this.isLoading || !this.name;
    }
  },
  methods: {
    onSubmit(e) {
      e.preventDefault()
      if (this.organization) {
        if (this.action === 'edit') {
          this.$store.dispatch('organizations/editOrganizationsItem', { ...this.organization, name: this.name });
        } else if (this.action === 'delete') {
          this.$store.dispatch('organizations/removeOrganizationsItem', this.organization);
        }
      } else {
        this.$store.dispatch('organizations/createOrganizationsItem', { name: this.name, vendor: this.vendor });
      }
    },
    actionSuccessHandler() {
      this.$emit('cancel');
      this.$store.dispatch('organizations/getOrganizations');
    }
  },
  watch: {
    organization: {
      immediate: true,
      handler(newVal) {
        if (newVal) {
          this.name = newVal.name;
        } else {
          this.name = '';
        }
      }
    },
    createOrganizationsItemIsLoading(newVal) {
      if (!newVal && this.createOrganizationsItemResult) {
        this.actionSuccessHandler();
      }
    },
    editOrganizationsItemIsLoading(newVal) {
      if (!newVal && this.editOrganizationsItemResult) {
        this.actionSuccessHandler();
      }
    },
    removeOrganizationsItemIsLoading(newVal) {
      if (!newVal && this.removeOrganizationsItemResult) {
        this.actionSuccessHandler();
      }
    },
  }
}

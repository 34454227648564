
import { mapState } from 'vuex';
import Loader from '../Loader'

export default {
  name: "OrganizationsList",
  components: {
    Loader,
  },
  props: {
    widgetClasses: String,
    organizations: Array,
  },
  emits: ['delete', 'edit', 'add'],
  computed: {
    ...mapState('organizations', [
      'getOrganizationsIsLoading',
      'removeOrganizationsItemIsLoading',
    ]),
    ...mapState('users', [
      'getCurrentProfileIsLoading',
    ]),
    isLoading() {
      return this.getCurrentProfileIsLoading ||
        this.getOrganizationsIsLoading ||
        this.removeOrganizationsItemIsLoading;
    }
  }
}
